<template>
    <list-table-layout
        class="user-list" 
        :header="`Users (${users.length})`"
        :columns="columns"
        :records="users"
        :model="UserModel"
        :lazy="true"
        >
        <template v-slot:table-controls>
            <router-link to="/users/create" class="button">&plus; Add user</router-link>
        </template>
        <!-- <template v-slot:rowcontrols="{record}">
            <router-link class="button" :to="'/users/' + record.record.id" >Details <i class="fas fa-external-link-square-alt"></i></router-link>
        </template> -->
        <!-- <template v-slot:openable="{row}" >
            <div class="components">
                <div class="component">
                    <header>
                        <h3>User details</h3> 
                        <div class="header-controls">
                            <a @click.prevent="" class="button button-3">
                                <i class="fas fa-user-slash"></i> Delete user
                            </a>
                            <a class="button">Reset password</a>
                            <a class="button" 
                                @click.prevent="editing = (editing === row.id ? false : row.id)"
                                :class="{'open' : editing === row.id}"
                            >
                                <i class="far fa-edit"></i> Edit user
                            </a>
                        </div>
                    </header>
                    <div>
                        <user-details-form :record="row" :editing="editing === row.id" @stop-editing="editing = (editing === row.id ? false : editing)"></user-details-form>
                    </div>
                </div>
            </div>
        </template> -->
    </list-table-layout>
</template>

<script>
import ListTableLayout from '../../components/layouts/ListTableLayout.vue'

import UserModel from '../../models/UserModel.js'

// import UserDetailsForm from '../../components/users/UserDetailsForm.vue';
import { ref } from '@vue/reactivity';
// import { watch } from '@vue/runtime-core';
import database from "@/store/database";

const columns = {
    'firstName' : {
        title : "Full Name",
        sortable: true,
        format: '{firstName} {lastName}'
    },
    'email' : {
        title: "Email Address",
        sortable: true
    },
    'role' : {
        title: "User Role",
        sortable: true,
    },
    // 'last-active-date' : {
    //     title: "Last Active",
    //     sortable: true
    // },
    // 'user-status' : {
    //     title: "Status",
    //     sortable: true,
    //     options: UserStatus
    // }
};

export default {
    components: {
        ListTableLayout,
        // UserDetailsForm
    },
    data: function(){
        return {
            editing: false
        }
    },
    setup(){
        const promises = [];
        const loaded = ref(false);

        const users = database[UserModel.name].list;

        Promise.all(promises).then(() => {
            loaded.value = true;
        });

        return {users, loaded, UserModel, columns}
    },
    methods: {
        getDeepProp: function(row, prop){
            return prop.split(".").reduce((o, x) => o === undefined ? o : o[x], row);
        },
    },
}
</script>
<style lang="scss">
.user-list{
    .user-details-form {
        .permissions,
        .locations .multiselect {
            ul{
                column-count: 2;
                column-gap: 20px;

            }
        }
    }
    .simple-table th.row-controls{
        width: 150px;
    }
}
</style>